<template>
  <div class="form-group row align-items-center col-sm-12 p-0 m-0 pt-1">
    <label
      :class="[
        'col-sn-6',
        'col-form-label',
        'pt-1',
        device !== 'desktop' ? 'p-0' : '',
      ]"
      >{{ $t('search-tab.age') }}</label
    >
    <div class="col-sn-6n p-0 m-0">
      <b-form-select
        class="age"
        v-model="currValue"
        :options="options"
        @change="change"
      ></b-form-select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormSelect } from 'bootstrap-vue';

export default {
  name: 'PaxAge',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: -1,
    },
    selected: {
      type: Number,
      default: -1,
    },
  },
  components: {
    BFormSelect,
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
  },
  data() {
    return {
      currValue: -1,
    };
  },
  watch: {
    selected(value) {
      this.currValue = Number(value);
    },
  },
  created() {
    this.currValue = Number(this.selected);
  },
  methods: {
    change() {
      this.$emit('change', {
        value: this.currValue,
        index: this.index,
      });
    },
  },
};
</script>

<style scoped>
.age {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  margin-top: 2px !important;
}
.col-form-label {
  width: 30px;
}
</style>
